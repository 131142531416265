import { Div, H1 } from '@modelberry/any-element/react'

const ErrorPage = () => {
  return (
    <Div>
      <H1>404</H1>
    </Div>
  )
}

export default ErrorPage
